.attorneyContainer {
    background: #FAFAF9;
    overflow: hidden;
    margin-top: 11em;
}

.NoattorneyContainer {
    background: #FAFAF9;
    overflow: hidden;
    margin-top: 0 !important;
}

.NoattorneyCardContainer {
    height: 100vh;
}

.buttonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3em 0;
}

.buttonContainer button {
    font-family: 'Lato', sans-serif;
    background-color: var(--color-primary);
    border: 0.1px solid #AFAFAF;
    color: var(--color-secondary);
    color:  #7578B8;
    height: 4em;
    width: 12%;
    outline: 0;
    cursor: pointer;
    margin: 0px;
    text-transform: capitalize;
    text-shadow: var(--shadow) 2px 2px;
    transition: var(--mainTransition);
}


.buttonContainer button:nth-child(2) {
    margin: 0px;
}


.attorneyCardContainer {
    margin: 4em 0 4em;
    padding: 0 10%;
}

.attorneyCard {
    background: #FFFFFF;
    border: .5px solid #ccc;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    margin: 0.3em 0 1.8em;
}

.attorneyCard:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}


.attorneyCard img {
    width: 100%;
    height: 400px;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
}

@media screen and (max-width: 560px) {
    .attorneyCard img {
        height: 320px;
    }
}

@media screen and (min-width: 990px) {
    .attorneyCard img {
        height: 500px;
        object-fit: cover;
    }
}

.attorneyCardName {
    padding: 10px .5em 1em;
    margin-bottom: 5px;
    margin-top: 20px;
}

.attorneyCard h2 {
    margin: 10px 0;
    font-size: 1.2em !important;
    color: var(--textColor);
}



.attorneyCard p {
    margin: 0 0 15px;
    font-size: .87em;
}

.attorneyCard button {
    background-color: var(--buttonColor);
    border: 0;
    outline: 0;
    color: var(--textColor);
    font-family: 'Lato', sans-serif;
    padding: 0.4em 0.8em;
    border-radius: 2px;
}


@media screen and (max-width: 400px) {
    .buttonContainer {
        display: block;
    }
}

@media screen and (max-width: 700px) {
    .attorneyContainer {
        margin-top: 0;
    }

    .attorneyCardContainer{
        margin: 9em 0 4em;
    }
}
