/*  */
* {
    margin: 0;
    padding: 0;
}

html,body {
    font-family: 'Montserrat', sans-serif;
    font-size: .95em;
}
