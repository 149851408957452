@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');

.loading {
    width: 5rem;
    height: 5rem;
    border: 3px solid var(--buttonColor);
    border-radius: 50%;
    border-top-color: var(--navColor);
    animation: spinner 0.6s linear infinite;
    /* margin: 3rem auto; */
    display: inline-block;
}

.letter {
    font-family: 'Monoton', cursive;
    position: relative;
    right: 0px;
    top: -54px;
}
.container {
    text-align: center;
    padding-top: 20rem;
    position: relative;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}