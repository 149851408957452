.profileSection {
    background-color: #fff;
    padding-top: 5rem;
    overflow: hidden;
    
}

.profileHeader {
    background: rgba(17, 19, 83, 0.8);
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

@media screen and (max-width: 1100px) {
    .profileHeader {
        padding: 0 7em;
    }
    
}



.profileHeader > div:first-child img {
    width: 350px;
}

.profileAddres {
    flex: 1;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    color: #fff;

}

.profileSocials {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 850px) {
    .profileHeader {
        padding: 2em 4em;
        display: block;
        height: auto;
        text-align: center;
    }
    .profileAddres {
        display: block;
        margin: 1em 0;
    }

    .profileSocials {
        display: inline-block;
    }

    .profileHeader > div:first-child img {
        width: 70%;
    }
    
}

@media screen and (max-width: 500px) {
    .profileHeader {
        padding: 2em;
        display: block;
        height: auto;
        text-align: center;
    }
    .profileHeader > div:first-child img {
        width: 100%;
        max-width: 350px;
    }
}

.profileSocials p {
    margin: 0;
}

.profileAddress p {
    margin: 10px 0;
}

.profileBio {
    margin: 12rem 8rem 3rem
}

@media screen and (max-width: 1024px) {
    .profileHeader {
        padding: 2em;
    }

    .profileBio {
        margin: 2em;
    }
    
}