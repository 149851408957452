.contactUsContainer{
    width: 100%;
    background-color: #fff;
    padding: 10rem 7rem 0;
}

@media screen and (max-width: 1024px) {
    .contactUsContainer{
        padding: 10rem 2rem 0;
    }
}
    


.contactUsContainer h1 {
    color: var(--textColor);
    font-style: normal;
    font-weight: bold;
}

.adressContainer{
    margin: 4em 0;
}

.adressContainerRow {
    height: 100%;
}

.adressContainerWrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 100%;
}

.adressContainerWrapper h3 {
    color: var(--textColor);
    font-weight: 600;
    margin-top: 2em;
}

.adressContainerWrapper p{
    font-size: 1em;
}


@media screen and (max-width: 990px) {
    .adressContainer {
        height: 100%;
        margin: 4rem 1rem;
    }
}

@media screen and (max-width: 600px) {
    .adressContainerWrapper {
        align-items: center;
        text-align: center;
    }
}


.contactForm {
    margin: 1em 0 5em 0;
}

.contactCard {
    background: #FFFFFF;
    box-shadow: 0px 5.25265px 21.0106px rgba(0, 0, 0, 0.1);
    border-radius: 13.1316px;
    width: 70%;
}

@media screen and (max-width: 990px) {
    .contactCard {
        width: 100%;
    }
}
    
.contactForm h2 {
    color: var(--textColor);
    margin-bottom: 1em;
}

/* .contactForm p {
    color: rgba(51, 51, 51, 0.7);
    font-weight: 600;
    font-size: 1em
} */

.contactForm form {
    padding: 4rem;
}

.contactForm form  div {
    display: flex;
}

.contactForm form > div:nth-child(3) {
    flex-direction: row-reverse;
}

.contactForm form div:first-child,
.contactForm form div:nth-child(2) {
    margin-bottom: 1em;
}

.contactForm form div:first-child div:first-child,
.contactForm form div:nth-child(2) div:first-child,
.contactText div:last-child {
    margin-right: 20px;
}

.contactFormMessage > div:last-child {
    margin-right: 20px;
}

.formModalRed {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    background: #CD113B;
    width: 100%;
    z-index: 10;
    padding: 2rem;
    color: #fff;
}

.formModalNormal {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    background: #FFC947;
    width: 100%;
    z-index: 10;
    padding: 2rem;
    color: #fff;
}

.formModal {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    background: #66DE93;
    width: 100%;
    z-index: 10;
    padding: 2rem;
    color: #fff;
}

.formModal div svg,
.formModalRed div svg,
.formModalNormal div svg {
    cursor: pointer;
}

.formModal div svg:hover,
.formModalRed div svg:hover,
.formModalNormal div svg:hover {
    color: rgba(0, 0, 0, 0.1)
}

@media  screen and (max-width: 580px) {
    .contactUsContainer {
        padding: 7em 0;
    }
    .contactForm form  div {
        display: block;
    }

    .contactForm h2,
    .contactUsContainer h1 {
        font-size: 2em !important;
    }

    .contactUsContainer h1 {
        text-align: center;
    }

    .contactFormMessage > div:last-child {
        margin-left: 0;
    }

    .contactForm form {
        padding: 2em;
    }

    .contactForm {
        margin: 0rem 1em;
    }

    .contactCard {
        width: 100%;
    }

    
}

.buttonWrapper  button{
    background-color: var(--navColor);
    color: #fff;
    padding: 12px;
}

