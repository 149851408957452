@import url(https://fonts.googleapis.com/css2?family=Monoton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,300;0,400;0,600;1,400&display=swap);
.nav_navContainer__1cfhg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    background: transparent;
    width: 100%;
    height: 80px;
    padding: 0 7rem;
    z-index: 6;
}

.nav_activeScroll__23UMn{
    background: var(--navColor); 
}

.nav_navLink__3XtXU {
    display: flex;
    align-items: center;
    margin: 0

}

.nav_navLink__3XtXU li {
    display: flex;
    align-items: center;
    height: 100%;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 0 0.9em;
    transition: var(--mainTransition);
}

.nav_navLink__3XtXU li a {
    color: #fff;
    text-transform: uppercase;
    font-size: .9em;
}

.nav_linkChange__22kMA  li a {
    color: var(--textColor) !important;
}


.nav_Active__UgHep {
    border-bottom: 3px solid #fff;   
}

.nav_activeScollLink__NY-YP ul li a,
.nav_activeScollLink__NY-YP svg{
    color: #fff !important;
}

.nav_dropDown__xqhTF,
.nav_dropDownEvent__z8RKe {
    position: absolute;
    top: 20px;    
    background: #FFFFFF;
    border-radius: 2px;
    transition: var(--mainTransition);
    padding: 0;    
}

.nav_dropDownScroll__4Zpe9 {
    background: var(--navColor);
}

.nav_dropDown__xqhTF {
    width: 300px;
}

.nav_dropDownEvent__z8RKe {
    width: 170px;
}

.nav_dropDown__xqhTF div,
.nav_dropDownEvent__z8RKe div {
    padding: 10px 0;
}

.nav_dropDown__xqhTF a,
.nav_dropDownEvent__z8RKe a {
    color: var(--navColor) !important;
    text-transform: capitalize !important; 
    padding:  0 0 0 .7em;
}

.nav_dropDown__xqhTF div:hover,
.nav_dropDownEvent__z8RKe div:hover {
    background: #F2F5FB;
    border-radius: 2px 2px 0px 0px
}

.nav_dropDownScroll__4Zpe9 a:hover {
    color: var(--navColor) !important;
} 

.nav_ActiveOther__1102e > a{
    border-bottom: 3px solid var(--navColor);   
}

.nav_ActiveOtherScroll__3n0Dk > a {
    border-bottom: 3px solid #fff !important;  
}




.nav_logoScroll__uMPLL {
    background: #fff;
    border-radius: 50px;
    padding: 7px;
}

.nav_logoScroll__uMPLL img {
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 768px) {
    .nav_logoScroll__uMPLL img {
        width: 25px;
        height: 25px;
    }
}
@media screen and (max-width: 1024px) {
    .nav_navLink__3XtXU {
        display: none;
    }
    .nav_navContainer__1cfhg {
        padding: 0 2rem;
    }

    .nav_hamburgerMenu__nPvdb  {
        color: #fff;
    }

    .nav_linkChange__22kMA  {
        color: var(--textColor);
    }


}

@media screen and (min-width: 1025px) {
    .nav_hamburgerMenu__nPvdb {
        display: none;
    }
}


.nav_ActiveAbout__yAwVM {
    background-color: #fff !important;
}


.footer_footerContainer__qkm9u {
    width: 100%;
}

.footer_footerBig__3OqKl {
    padding: 2em 4em 0;
    background-color: var(--footerBig);
    display: flex;
}

.footer_footerBig__3OqKl h2 {
    text-transform: uppercase;
    font-size: 1.1em !important;
    margin-bottom: 0.95em;
}

.footer_footerBig__3OqKl h3 {
    font-size: 0.9em !important;
}

.footer_footerBig__3OqKl p {
    font-size: 0.7em;
}

.footer_footerAdress__3QMAm,
.footer_footerNews__3_p7S {
    margin: 0;
    color: #fff;
    
}


.footer_footerNews__3_p7S p {
    width: 70%;
}

.footer_footerAdress__3QMAm > div {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
}

.footer_footerAdress__3QMAm > div div:nth-child(2) {
    margin: 0 1em;
}

.footer_publications__1O86K {
    flex: 1 1;
    color: #fff;
}

.footer_footerSmall__2_Hky {
    background-color: var(--footerSmall);
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    width: 100%;
}

.footer_footerSmall__2_Hky p {
    margin: 0;
    padding: 1em 4em;
    color: #fff;
    font-size: 0.8em;
}

.footer_footerNewsMobileContent__2Zxyk {
    -webkit-animation: footer_move__3_iAN 30s ease-in-out infinite alternate;
            animation: footer_move__3_iAN 30s ease-in-out infinite alternate;
    
}

@-webkit-keyframes footer_move__3_iAN {
    33.33% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }
    66.66% {
        -webkit-transform: translateY(-100px);
                transform: translateY(-100px);
    }
    100% {
        -webkit-transform: translateY(-150px);
                transform: translateY(-150px);
    }
}

@keyframes footer_move__3_iAN {
    33.33% {
        -webkit-transform: translateY(-50px);
                transform: translateY(-50px);
    }
    66.66% {
        -webkit-transform: translateY(-100px);
                transform: translateY(-100px);
    }
    100% {
        -webkit-transform: translateY(-150px);
                transform: translateY(-150px);
    }
}

@media screen and (max-width: 650px) {
    .footer_footerBig__3OqKl {
        display: block;
    }

    .footer_footerNews__3_p7S h2 {
        cursor: pointer;
        margin: 0;
    }

    .footer_footerNewsMobile__RjQIg {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }
}

@media screen and (max-width: 768px) {
    .footer_footerAdress__3QMAm > div {
        display: block;
    }
    .footer_footerAdress__3QMAm > div div:nth-child(2) {
        margin: 0;
    }

    .footer_footerBig__3OqKl {
        padding: 2em 2em;
    }

    .footer_footerNews__3_p7S p {
        width: 100%;
    }

    .footer_footerNews__3_p7S {
        position: relative;
        
    }
    

}
.home_carouselContainer__GxDmd::before {
    position: relative;
}

.home_carouselContainer__GxDmd::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 11;
    
}
    

.home_aboutCol__1g6Na div:first-child{
    /* display: flex;
    align-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.home_homeContainer__1McRK {
    width: 100%;
    height: 100%;
    background-color: #F8F7F2;
    position: relative;
    z-index: 1;
    overflow: hidden;

    div::first-child {
        
    }
}

.home_homeContainer__1McRK h2 {
    color: var(--textColor);
    font-size: 3em !important;
    font-weight: 600;
}

.home_homeContainer__1McRK h5 {
    font-weight: 600;
    color: var(--textColor);
}

.home_homeCarousel__1585e {
    background: rgba(0, 0, 0, 0.2); /* Black see-through */
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.home_aboutAbu__3Gacu {
    /* background-image: url(../images/home.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-attachment: fixed; */
    margin: 6em 7em 5em;
}

.home_aboutAbu__3Gacu p {
    text-align: justify;
    line-height: 1.8;
}

.home_aboutAbu__3Gacu img {
    width: 100%;
    object-fit: fill;
    height: 100%;
}

.home_aboutBrief__1CDgi > div:first-child h2 {
    width: 75%;
    margin: 0 auto
}

.home_aboutBrief__1CDgi > div:first-child p {
    color: var(--textColor);
    width: 75%;
    margin: 0 auto;
    margin-top: 2em;
    line-height: 2;
}

.home_practiceBrief__1oSzv  {
    margin: 6em 7em 5em;
    position: relative;
}

@media screen and (max-width: 995px) {
    .home_practiceBrief__1oSzv {
        margin: 6em 2em 0;
    }
}


.home_practiceBrief__1oSzv h2 {
    margin-bottom: 1.1em;
}

.home_practiceBriefCard__sUK0U {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 1em .9em;
    height: 250px;
}

.home_practiceBriefCard__sUK0U > div:first-child {
    padding: 2em .6em;
}

.home_practiceBriefCard__sUK0U div{
    padding: 0 .6em 1em
}

.home_practiceBriefCard__sUK0U > div:last-child  h5{
    margin-bottom: 1em;
}

.home_practiceBriefCard__sUK0U > div:last-child  p{
    color: var(--textColor);
}

.home_practiceAreaMore__2qid4 {
    position: absolute;
    right: -61px;
    bottom: -6px;
}

.home_ourPhilosophy__2pDDc {
    background: #F8F7F2;
    padding: 5em 4em 3em;
}

.home_ourPhilosophyContent__1bDUd {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home_ourPhilosophy__2pDDc h2 {
    margin-bottom: .5em;
}

.home_ourPhilosophy__2pDDc p {
    text-align: justify;
    margin: 0;
    line-height: var(--lineHeight);
    width: 100%;
}

@media screen and (max-width: 995px) {
    .home_ourPhilosophy__2pDDc {
        padding: 3em 2em 2em;
    }
}

.home_meetCounselBrief__2NBIu {
    margin: 4em 7em 5em;
    position: relative;
}

.home_meetCounselBrief__2NBIu > div:first-child {
    margin-bottom: 4em;
}

.home_meetCounselBriefName__3Exeg {
    margin-top: 2em;
}

.home_meetCounselBriefName__3Exeg h5 {
    font-size: 1.6em !important
}

.home_meetCounselBriefName__3Exeg p {
    color: var(--textColor);
}

.home_meetCounselBriefMore__10j1b {
    position: absolute;
    right: -70px;
    bottom: 16px;
    margin: 0;
}


@media screen and (max-width: 600px) {
    .home_homeContainer__1McRK h2 {
        font-size: 1.5em !important;
        font-weight: 600;
    }

    .home_homeCarousel__1585e {
        height: 200px;
    }
    
    .home_aboutBrief__1CDgi > div:first-child h2 {
        width: 100%;
    }

    .home_aboutBrief__1CDgi > div:first-child p {
        width: 100%;
        margin: 2em auto;
    }

    .home_aboutBrief__1CDgi > div:last-child img {
        width: 100%;
    }

    .home_practiceBriefCard__sUK0U> div:last-child h5 {
        font-size: 14px !important;
        text-align: center;
        margin: 0;
    }
    .home_practiceBriefCard__sUK0U > div:last-child p {
        margin: 10px 0 0;
        font-size: 12px;
        text-align: center;
    }

    .home_practiceBriefCard__sUK0U > div:first-child {
        padding: 20px 10px;
    }

    .home_meetCounselBriefName__3Exeg h5 {
        font-size: 1.1em !important;
    }
    .home_meetCounselBrief__2NBIu {
        margin: 2rem 0;
    }

    .home_aboutAbu__3Gacu {
        margin: 2em 1em;
    }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
    .home_aboutBrief__1CDgi > div:first-child h2 {
        width: 80%;
    }

    .home_aboutBrief__1CDgi > div:first-child p {
        width: 70%;
        margin: 2em auto;
    }

    .home_aboutBrief__1CDgi > div:last-child img {
        width: 70%;
    }
    
}
@media screen and (max-width: 1024px) {
    .home_aboutBrief__1CDgi {
        display: block;
    }
    .home_aboutBrief__1CDgi > div:first-child h2 {
        text-align: center;
    }

    .home_aboutBrief__1CDgi > div:last-child,
    .home_meetCounselBrief__2NBIu h2  {
        text-align: center;
    }

    .home_practiceBrief__1oSzv h2 {
        text-align: center;
        margin-bottom: 1em;
    }
    .home_ourPhilosophy__2pDDc p {
        width: 100%;
    }

    .home_meetCounselBriefImg__3he9H img{
        width: 90%;
    }

    .home_meetCounselBrief__2NBIu > div {
        text-align: center;
    }
}


.carousel_CarouselC__3jgnU p {
    margin: 0;
    text-align: initial;
    font-weight: 700;
    line-height: 1.8 !important;
}

.carousel_CarouselC__3jgnU h1 {
    text-align: initial;
    margin-bottom: 2rem;
    color: #fff;
    font-weight: 700;
}

@media screen and (max-width: 370px) {
    .carousel_CarouselC__3jgnU h1 {
        font-size: .9em !important;
    }

}

@media screen and (max-width: 550px) {
    .carousel_CarouselC__3jgnU p {
        display: none;
    }
    .carousel_CarouselContainer__2FXYl img {
        height: 200px;
    }
}

@media screen and (max-width: 1024px) {
    .carousel_CarouselC__3jgnU h1 {
        font-size: 1.2em !important;
    }

    .carousel_CarouselC__3jgnU p {
        font-size: .7em;
        font-weight: 600;
    }
}


.loader_loading__gRZ2D {
    width: 5rem;
    height: 5rem;
    border: 3px solid var(--buttonColor);
    border-radius: 50%;
    border-top-color: var(--navColor);
    -webkit-animation: loader_spinner__1fVxg 0.6s linear infinite;
            animation: loader_spinner__1fVxg 0.6s linear infinite;
    /* margin: 3rem auto; */
    display: inline-block;
}

.loader_letter__2idn9 {
    font-family: 'Monoton', cursive;
    position: relative;
    right: 0px;
    top: -54px;
}
.loader_container__12jWy {
    text-align: center;
    padding-top: 20rem;
    position: relative;
}

@-webkit-keyframes loader_spinner__1fVxg {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes loader_spinner__1fVxg {
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
.counsel_attorneyContainer__2S9km {
    background: #FAFAF9;
    overflow: hidden;
    margin-top: 11em;
}

.counsel_NoattorneyContainer__zFU_O {
    background: #FAFAF9;
    overflow: hidden;
    margin-top: 0 !important;
}

.counsel_NoattorneyCardContainer__ULqgx {
    height: 100vh;
}

.counsel_buttonContainer__2imc8 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3em 0;
}

.counsel_buttonContainer__2imc8 button {
    font-family: 'Lato', sans-serif;
    background-color: var(--color-primary);
    border: 0.1px solid #AFAFAF;
    color: var(--color-secondary);
    color:  #7578B8;
    height: 4em;
    width: 12%;
    outline: 0;
    cursor: pointer;
    margin: 0px;
    text-transform: capitalize;
    text-shadow: var(--shadow) 2px 2px;
    transition: var(--mainTransition);
}


.counsel_buttonContainer__2imc8 button:nth-child(2) {
    margin: 0px;
}


.counsel_attorneyCardContainer__3wJQx {
    margin: 4em 0 4em;
    padding: 0 10%;
}

.counsel_attorneyCard__2mBtP {
    background: #FFFFFF;
    border: .5px solid #ccc;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
    margin: 0.3em 0 1.8em;
}

.counsel_attorneyCard__2mBtP:hover {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}


.counsel_attorneyCard__2mBtP img {
    width: 100%;
    height: 400px;
    border-radius: 10px 10px 0 0;
    object-fit: cover;
}

@media screen and (max-width: 560px) {
    .counsel_attorneyCard__2mBtP img {
        height: 320px;
    }
}

@media screen and (min-width: 990px) {
    .counsel_attorneyCard__2mBtP img {
        height: 500px;
        object-fit: cover;
    }
}

.counsel_attorneyCardName__AJcX2 {
    padding: 10px .5em 1em;
    margin-bottom: 5px;
    margin-top: 20px;
}

.counsel_attorneyCard__2mBtP h2 {
    margin: 10px 0;
    font-size: 1.2em !important;
    color: var(--textColor);
}



.counsel_attorneyCard__2mBtP p {
    margin: 0 0 15px;
    font-size: .87em;
}

.counsel_attorneyCard__2mBtP button {
    background-color: var(--buttonColor);
    border: 0;
    outline: 0;
    color: var(--textColor);
    font-family: 'Lato', sans-serif;
    padding: 0.4em 0.8em;
    border-radius: 2px;
}


@media screen and (max-width: 400px) {
    .counsel_buttonContainer__2imc8 {
        display: block;
    }
}

@media screen and (max-width: 700px) {
    .counsel_attorneyContainer__2S9km {
        margin-top: 0;
    }

    .counsel_attorneyCardContainer__3wJQx{
        margin: 9em 0 4em;
    }
}

.contact_contactUsContainer__1Nwre{
    width: 100%;
    background-color: #fff;
    padding: 10rem 7rem 0;
}

@media screen and (max-width: 1024px) {
    .contact_contactUsContainer__1Nwre{
        padding: 10rem 2rem 0;
    }
}
    


.contact_contactUsContainer__1Nwre h1 {
    color: var(--textColor);
    font-style: normal;
    font-weight: bold;
}

.contact_adressContainer__3PA_V{
    margin: 4em 0;
}

.contact_adressContainerRow__1YRXK {
    height: 100%;
}

.contact_adressContainerWrapper__3RwN- {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    height: 100%;
}

.contact_adressContainerWrapper__3RwN- h3 {
    color: var(--textColor);
    font-weight: 600;
    margin-top: 2em;
}

.contact_adressContainerWrapper__3RwN- p{
    font-size: 1em;
}


@media screen and (max-width: 990px) {
    .contact_adressContainer__3PA_V {
        height: 100%;
        margin: 4rem 1rem;
    }
}

@media screen and (max-width: 600px) {
    .contact_adressContainerWrapper__3RwN- {
        align-items: center;
        text-align: center;
    }
}


.contact_contactForm__qjiiV {
    margin: 1em 0 5em 0;
}

.contact_contactCard__3V1ZA {
    background: #FFFFFF;
    box-shadow: 0px 5.25265px 21.0106px rgba(0, 0, 0, 0.1);
    border-radius: 13.1316px;
    width: 70%;
}

@media screen and (max-width: 990px) {
    .contact_contactCard__3V1ZA {
        width: 100%;
    }
}
    
.contact_contactForm__qjiiV h2 {
    color: var(--textColor);
    margin-bottom: 1em;
}

/* .contactForm p {
    color: rgba(51, 51, 51, 0.7);
    font-weight: 600;
    font-size: 1em
} */

.contact_contactForm__qjiiV form {
    padding: 4rem;
}

.contact_contactForm__qjiiV form  div {
    display: flex;
}

.contact_contactForm__qjiiV form > div:nth-child(3) {
    flex-direction: row-reverse;
}

.contact_contactForm__qjiiV form div:first-child,
.contact_contactForm__qjiiV form div:nth-child(2) {
    margin-bottom: 1em;
}

.contact_contactForm__qjiiV form div:first-child div:first-child,
.contact_contactForm__qjiiV form div:nth-child(2) div:first-child,
.contact_contactText__MQ8F8 div:last-child {
    margin-right: 20px;
}

.contact_contactFormMessage__1sF4y > div:last-child {
    margin-right: 20px;
}

.contact_formModalRed__2hbjr {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    background: #CD113B;
    width: 100%;
    z-index: 10;
    padding: 2rem;
    color: #fff;
}

.contact_formModalNormal__1fyWs {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    background: #FFC947;
    width: 100%;
    z-index: 10;
    padding: 2rem;
    color: #fff;
}

.contact_formModal__Pi8wk {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0px;
    background: #66DE93;
    width: 100%;
    z-index: 10;
    padding: 2rem;
    color: #fff;
}

.contact_formModal__Pi8wk div svg,
.contact_formModalRed__2hbjr div svg,
.contact_formModalNormal__1fyWs div svg {
    cursor: pointer;
}

.contact_formModal__Pi8wk div svg:hover,
.contact_formModalRed__2hbjr div svg:hover,
.contact_formModalNormal__1fyWs div svg:hover {
    color: rgba(0, 0, 0, 0.1)
}

@media  screen and (max-width: 580px) {
    .contact_contactUsContainer__1Nwre {
        padding: 7em 0;
    }
    .contact_contactForm__qjiiV form  div {
        display: block;
    }

    .contact_contactForm__qjiiV h2,
    .contact_contactUsContainer__1Nwre h1 {
        font-size: 2em !important;
    }

    .contact_contactUsContainer__1Nwre h1 {
        text-align: center;
    }

    .contact_contactFormMessage__1sF4y > div:last-child {
        margin-left: 0;
    }

    .contact_contactForm__qjiiV form {
        padding: 2em;
    }

    .contact_contactForm__qjiiV {
        margin: 0rem 1em;
    }

    .contact_contactCard__3V1ZA {
        width: 100%;
    }

    
}

.contact_buttonWrapper__2PPqE  button{
    background-color: var(--navColor);
    color: #fff;
    padding: 12px;
}


.about_founderRow__1SfhL {
    margin-top: 2em;
}

.about_aboutContainer__2fZlv {
    overflow: hidden;
    margin-bottom: 4rem;
}

.about_aboutImgDiv__3yO_Z {
    width: 100%;
    height: 100%;
}
.about_aboutImgDiv__3yO_Z::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 84px;
    background-color: rgba(0, 0, 0, 0.5);
}

.about_aboutImgDiv__3yO_Z::before{
    position: relative;
    height: 100%;
}


@media screen and (max-width: 575px) {
    .about_founderRow__1SfhL {
        margin-top: 0;
    }
}
/* .aboutContainer img{
    width: 100%;
} */

.about_aboutContainer__2fZlv h2 {
    font-size: 3em !important;
    font-weight: 600;
    color: var(--navColor);
    margin-bottom: 1rem;
}

.about_bestlawFirm__1qHSA {
    margin: 5rem 7rem;
}

.about_bestlawFirm__1qHSA img {
    object-fit: cover;
    height: 100%;
}

.about_bestlawFirm__1qHSA p {
    line-height: 1.8;
    white-space: normal;
    text-align: justify;
}
.about_bestlawFirmP__1gEK2{
    margin: 2em 0;
}

.about_whychooseUs__2gGCK {
    margin: 0 7em 5em;
}


.about_whyCol__D0vm6 > div {
    margin: 3em 0 0;
}

.about_whychooseUs__2gGCK h3 {
    font-weight: 600;
}

.about_whyCol__D0vm6 p {
    line-height: 1.8;
    width: 90%;
    text-align: justify;
}


.about_meetheFounder__kNIyS{
    margin: 0 7em;
}

.about_founderCol__3iEWI {
    margin-top: 3em !important;
}

.about_meetheFounder__kNIyS img {
    width: 100%;
    object-fit: contain;
}

.about_meetheFounder__kNIyS h2{
    text-align: center;
}

.about_meetheFounder__kNIyS p{
    /* width: 80%; */
    margin: 1em auto 0;
    line-height: 2.5;
    white-space: normal;
    text-align: justify;
}

.about_meetheFounder__kNIyS button {
    background-color: var(--buttonColor);
    border: 0;
    outline: 0;
    color: var(--textColor);
    font-family: 'Lato', sans-serif;
    padding: 0.4em 0.8em;
    border-radius: 2px;
    width: 40%;
    height: 4em;
    font-size: 1.1em;
    font-weight: 500;
}

.about_founderCol__3iEWI > div {
    /* width: 80%;
    margin: 2em auto 0; */
    margin-top: 3em;
}

.about_needHelp__2BvSD{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7em 7em 4em;
}

.about_needHelp__2BvSD button{
    background: transparent;
    border: 1px solid var(--buttonColor);
    font-size: 1.1em;
    text-transform: capitalize;
    text-transform: capitalize;
    padding: 0.8em 2.5em;
    color: var(--textColor);
    font-family: 'Lato', sans-serif;
    border-radius: 2px;
    transition: var(--mainTransition);
}

.about_needHelp__2BvSD button:hover{
    background: var(--buttonColor);
} 

.about_foundimageMobile__1ppZ6 {
    display: none;
}

@media screen and (max-width: 1196px) {
    .about_meetheFounder__kNIyS .about_largefounderimg__2sQGP {
        width: 60%;
        display: none;
    }

    .about_foundimageMobile__1ppZ6 {
        display: block;
    }

}

@media screen and (max-width: 468px) {
    .about_foundimageMobile__1ppZ6 img {
        height: 380px;
        object-fit: cover;
    }

    .about_whyCol__D0vm6 h3 {
        font-size: 1.2em!important;
    }
}

@media screen and (max-width: 1024px) {
    .about_aboutImgDiv__3yO_Z img {
        /* height: 180px; */
    }
    .about_bestlawFirm__1qHSA {
        margin: 2em;
    }
    .about_aboutContainer__2fZlv h2 {
        font-size: 1.5em !important;
        font-weight: 600;
        text-align: center;
    }

    .about_aboutContainer__2fZlv img {
        width: 100%;
    }

    .about_bestlawFirmP__1gEK2 {
        margin: 2em 1em;
    }

    .about_whychooseUs__2gGCK {
        margin: 2rem;
    }

    .about_whyCol__D0vm6 {
        padding: 0 2em !important;
    }

    .about_whyCol__D0vm6 p {
        width: 100%;
    }

    .about_meetheFounder__kNIyS {
        margin: 2em;
    }

    .about_meetheFounder__kNIyS p {
        width: 100%;
        margin: 1em auto 0;
        line-height: 2;
    }

    
    .about_founderCol__3iEWI > div {
        width: 100%;
        text-align: center;
        margin: 2em 0 0;
    }

    .about_founderCol__3iEWI div button {
        width: 100%;
    }

    .about_needHelp__2BvSD{
        display: block;
        text-align: center;
        margin: 3em 0;
    }

    
}

.modal-backdrop.show {
    opacity: .9!important;
}



.needHelp_InputFlex__HBnWb {
    display: flex !important;
    margin: 2em 0;
}

.needHelp_inputInput__2__CN {
    width: 60%;
}

.needHelp_InputText__3Xnua {
    display: flex !important;
    flex-direction: column-reverse;
}

.needHelp_aboutModalAdjust__1cma9 {
    margin-right: 1.5em;
}

.needHelp_aboutModalAdjustDown__2ySfU {
    margin-bottom: 1.7rem;
}

.needHelp_aboutBtn__2V_El {
    margin: 1em 0;
}
.profile_profileSection__ULWvI {
    background-color: #fff;
    padding-top: 5rem;
    overflow: hidden;
    
}

.profile_profileHeader__Hz90h {
    background: rgba(17, 19, 83, 0.8);
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
}

@media screen and (max-width: 1100px) {
    .profile_profileHeader__Hz90h {
        padding: 0 7em;
    }
    
}



.profile_profileHeader__Hz90h > div:first-child img {
    width: 350px;
}

.profile_profileAddres__2wKtF {
    flex: 1 1;
    display: flex;
    justify-content: space-evenly;
    align-items: baseline;
    color: #fff;

}

.profile_profileSocials__3zcRV {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 850px) {
    .profile_profileHeader__Hz90h {
        padding: 2em 4em;
        display: block;
        height: auto;
        text-align: center;
    }
    .profile_profileAddres__2wKtF {
        display: block;
        margin: 1em 0;
    }

    .profile_profileSocials__3zcRV {
        display: inline-block;
    }

    .profile_profileHeader__Hz90h > div:first-child img {
        width: 70%;
    }
    
}

@media screen and (max-width: 500px) {
    .profile_profileHeader__Hz90h {
        padding: 2em;
        display: block;
        height: auto;
        text-align: center;
    }
    .profile_profileHeader__Hz90h > div:first-child img {
        width: 100%;
        max-width: 350px;
    }
}

.profile_profileSocials__3zcRV p {
    margin: 0;
}

.profile_profileAddress__GUvQF p {
    margin: 10px 0;
}

.profile_profileBio__1WdOP {
    margin: 12rem 8rem 3rem
}

@media screen and (max-width: 1024px) {
    .profile_profileHeader__Hz90h {
        padding: 2em;
    }

    .profile_profileBio__1WdOP {
        margin: 2em;
    }
    
}
:root {
  --navColor: #10146b;
  --buttonColor: #d9cb39;
  --textColor: #111353;
  --footerBig: #171a24;
  --footerSmall: #7e91ad;
  --mainTransition: 0.35s linear;
  --lineHeight: 2;
  --buttonBordersize: 0.125em;
  --color-primary: rgba(255, 255, 255, 0.616);
  --color-secondary: black;
  --color-tertiary: dodgerblue;
  --shadow: rgba(0, 0, 0, 0.1);
  --space: 1rem;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

h1,
h2,
h3,
h5,
h6 {
  font-family: "Source Serif Pro", serif;
  font-weight: 500;
  font-style: normal;
}

h1 {
  font-size: 3em !important;
}

h2 {
  font-size: 2em !important;
}

h3 {
  font-size: 1.45em !important;
}

h5 {
  font-size: 1.3em !important;
}

h6 {
  font-size: 1em !important;
}

a {
  text-decoration: none !important;
}

.fabr {
  background-color: #d9cb39 !important;
  background-color: var(--buttonColor) !important;
}

.MainCarousel .carousel-caption {
  position: absolute;
  right: 0;
  left: 35%;
  top: 30%;
  z-index: 10;
  color: #fff;
}

.react-multi-carousel-list {
  position: revert;
}

.react-multiple-carousel__arrow {
  top: 14px;
  background: #e5e5e5;
  min-width: 30px;
  min-height: 30px;
  z-index: 1;
}

.react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.342);
}

.react-multiple-carousel__arrow--left {
  left: calc(31% + 1px);
}

.react-multiple-carousel__arrow--right {
  right: calc(62% + 1px);
}

.react-multiple-carousel__arrow::before {
  font-size: 15px;
  color: #111353;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #7578b8;
  padding: 0.9em 3px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #d9cb39 !important;
  background-color: var(--buttonColor) !important;
  border-radius: 0;
}

.nav-item {
  border-bottom: 1.07937px solid #afafaf;
}
@media screen and (max-width: 600px) {
  .MainCarousel .carousel-caption {
    right: 0;
    top: initial;
  }

  .react-multiple-carousel__arrow {
    top: initial;
    background: #e5e5e5;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(-60px + 1px);
  }

  .react-multiple-carousel__arrow--right {
    right: calc(-60px + 1px);
  }
  .react-multi-carousel-track {
    left: -23px;
  }

  .react-multi-carousel-track li {
    width: 200px !important;
  }
}

/*  */
* {
    margin: 0;
    padding: 0;
}

html,body {
    font-family: 'Montserrat', sans-serif;
    font-size: .95em;
}

