@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,300;0,400;0,600;1,400&display=swap");

@import "~react-image-gallery/styles/css/image-gallery.css";

:root {
  --navColor: #10146b;
  --buttonColor: #d9cb39;
  --textColor: #111353;
  --footerBig: #171a24;
  --footerSmall: #7e91ad;
  --mainTransition: 0.35s linear;
  --lineHeight: 2;
  --buttonBordersize: 0.125em;
  --color-primary: rgba(255, 255, 255, 0.616);
  --color-secondary: black;
  --color-tertiary: dodgerblue;
  --shadow: rgba(0, 0, 0, 0.1);
  --space: 1rem;
}

html {
  scroll-behavior: smooth;
  box-sizing: border-box;
}

h1,
h2,
h3,
h5,
h6 {
  font-family: "Source Serif Pro", serif;
  font-weight: 500;
  font-style: normal;
}

h1 {
  font-size: 3em !important;
}

h2 {
  font-size: 2em !important;
}

h3 {
  font-size: 1.45em !important;
}

h5 {
  font-size: 1.3em !important;
}

h6 {
  font-size: 1em !important;
}

a {
  text-decoration: none !important;
}

.fabr {
  background-color: var(--buttonColor) !important;
}

.MainCarousel .carousel-caption {
  position: absolute;
  right: 0;
  left: 35%;
  top: 30%;
  z-index: 10;
  color: #fff;
}

.react-multi-carousel-list {
  position: revert;
}

.react-multiple-carousel__arrow {
  top: 14px;
  background: #e5e5e5;
  min-width: 30px;
  min-height: 30px;
  z-index: 1;
}

.react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.342);
}

.react-multiple-carousel__arrow--left {
  left: calc(31% + 1px);
}

.react-multiple-carousel__arrow--right {
  right: calc(62% + 1px);
}

.react-multiple-carousel__arrow::before {
  font-size: 15px;
  color: #111353;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
  color: #7578b8;
  padding: 0.9em 3px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: var(--buttonColor) !important;
  border-radius: 0;
}

.nav-item {
  border-bottom: 1.07937px solid #afafaf;
}
@media screen and (max-width: 600px) {
  .MainCarousel .carousel-caption {
    right: 0;
    top: initial;
  }

  .react-multiple-carousel__arrow {
    top: initial;
    background: #e5e5e5;
  }
  .react-multiple-carousel__arrow--left {
    left: calc(-60px + 1px);
  }

  .react-multiple-carousel__arrow--right {
    right: calc(-60px + 1px);
  }
  .react-multi-carousel-track {
    left: -23px;
  }

  .react-multi-carousel-track li {
    width: 200px !important;
  }
}
