.founderRow {
    margin-top: 2em;
}

.aboutContainer {
    overflow: hidden;
    margin-bottom: 4rem;
}

.aboutImgDiv {
    width: 100%;
    height: 100%;
}
.aboutImgDiv::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 84px;
    background-color: rgba(0, 0, 0, 0.5);
}

.aboutImgDiv::before{
    position: relative;
    height: 100%;
}


@media screen and (max-width: 575px) {
    .founderRow {
        margin-top: 0;
    }
}
/* .aboutContainer img{
    width: 100%;
} */

.aboutContainer h2 {
    font-size: 3em !important;
    font-weight: 600;
    color: var(--navColor);
    margin-bottom: 1rem;
}

.bestlawFirm {
    margin: 5rem 7rem;
}

.bestlawFirm img {
    object-fit: cover;
    height: 100%;
}

.bestlawFirm p {
    line-height: 1.8;
    white-space: normal;
    text-align: justify;
}
.bestlawFirmP{
    margin: 2em 0;
}

.whychooseUs {
    margin: 0 7em 5em;
}


.whyCol > div {
    margin: 3em 0 0;
}

.whychooseUs h3 {
    font-weight: 600;
}

.whyCol p {
    line-height: 1.8;
    width: 90%;
    text-align: justify;
}


.meetheFounder{
    margin: 0 7em;
}

.founderCol {
    margin-top: 3em !important;
}

.meetheFounder img {
    width: 100%;
    object-fit: contain;
}

.meetheFounder h2{
    text-align: center;
}

.meetheFounder p{
    /* width: 80%; */
    margin: 1em auto 0;
    line-height: 2.5;
    white-space: normal;
    text-align: justify;
}

.meetheFounder button {
    background-color: var(--buttonColor);
    border: 0;
    outline: 0;
    color: var(--textColor);
    font-family: 'Lato', sans-serif;
    padding: 0.4em 0.8em;
    border-radius: 2px;
    width: 40%;
    height: 4em;
    font-size: 1.1em;
    font-weight: 500;
}

.founderCol > div {
    /* width: 80%;
    margin: 2em auto 0; */
    margin-top: 3em;
}

.needHelp{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7em 7em 4em;
}

.needHelp button{
    background: transparent;
    border: 1px solid var(--buttonColor);
    font-size: 1.1em;
    text-transform: capitalize;
    text-transform: capitalize;
    padding: 0.8em 2.5em;
    color: var(--textColor);
    font-family: 'Lato', sans-serif;
    border-radius: 2px;
    transition: var(--mainTransition);
}

.needHelp button:hover{
    background: var(--buttonColor);
} 

.foundimageMobile {
    display: none;
}

@media screen and (max-width: 1196px) {
    .meetheFounder .largefounderimg {
        width: 60%;
        display: none;
    }

    .foundimageMobile {
        display: block;
    }

}

@media screen and (max-width: 468px) {
    .foundimageMobile img {
        height: 380px;
        object-fit: cover;
    }

    .whyCol h3 {
        font-size: 1.2em!important;
    }
}

@media screen and (max-width: 1024px) {
    .aboutImgDiv img {
        /* height: 180px; */
    }
    .bestlawFirm {
        margin: 2em;
    }
    .aboutContainer h2 {
        font-size: 1.5em !important;
        font-weight: 600;
        text-align: center;
    }

    .aboutContainer img {
        width: 100%;
    }

    .bestlawFirmP {
        margin: 2em 1em;
    }

    .whychooseUs {
        margin: 2rem;
    }

    .whyCol {
        padding: 0 2em !important;
    }

    .whyCol p {
        width: 100%;
    }

    .meetheFounder {
        margin: 2em;
    }

    .meetheFounder p {
        width: 100%;
        margin: 1em auto 0;
        line-height: 2;
    }

    
    .founderCol > div {
        width: 100%;
        text-align: center;
        margin: 2em 0 0;
    }

    .founderCol div button {
        width: 100%;
    }

    .needHelp{
        display: block;
        text-align: center;
        margin: 3em 0;
    }

    
}
