.carouselContainer::before {
    position: relative;
}

.carouselContainer::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 11;
    
}
    

.aboutCol div:first-child{
    /* display: flex;
    align-items: center; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homeContainer {
    width: 100%;
    height: 100%;
    background-color: #F8F7F2;
    position: relative;
    z-index: 1;
    overflow: hidden;

    div::first-child {
        
    }
}

.homeContainer h2 {
    color: var(--textColor);
    font-size: 3em !important;
    font-weight: 600;
}

.homeContainer h5 {
    font-weight: 600;
    color: var(--textColor);
}

.homeCarousel {
    background: rgba(0, 0, 0, 0.2); /* Black see-through */
    height: fit-content;
}

.aboutAbu {
    /* background-image: url(../images/home.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;
    background-attachment: fixed; */
    margin: 6em 7em 5em;
}

.aboutAbu p {
    text-align: justify;
    line-height: 1.8;
}

.aboutAbu img {
    width: 100%;
    object-fit: fill;
    height: 100%;
}

.aboutBrief > div:first-child h2 {
    width: 75%;
    margin: 0 auto
}

.aboutBrief > div:first-child p {
    color: var(--textColor);
    width: 75%;
    margin: 0 auto;
    margin-top: 2em;
    line-height: 2;
}

.practiceBrief  {
    margin: 6em 7em 5em;
    position: relative;
}

@media screen and (max-width: 995px) {
    .practiceBrief {
        margin: 6em 2em 0;
    }
}


.practiceBrief h2 {
    margin-bottom: 1.1em;
}

.practiceBriefCard {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 1em .9em;
    height: 250px;
}

.practiceBriefCard > div:first-child {
    padding: 2em .6em;
}

.practiceBriefCard div{
    padding: 0 .6em 1em
}

.practiceBriefCard > div:last-child  h5{
    margin-bottom: 1em;
}

.practiceBriefCard > div:last-child  p{
    color: var(--textColor);
}

.practiceAreaMore {
    position: absolute;
    right: -61px;
    bottom: -6px;
}

.ourPhilosophy {
    background: #F8F7F2;
    padding: 5em 4em 3em;
}

.ourPhilosophyContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ourPhilosophy h2 {
    margin-bottom: .5em;
}

.ourPhilosophy p {
    text-align: justify;
    margin: 0;
    line-height: var(--lineHeight);
    width: 100%;
}

@media screen and (max-width: 995px) {
    .ourPhilosophy {
        padding: 3em 2em 2em;
    }
}

.meetCounselBrief {
    margin: 4em 7em 5em;
    position: relative;
}

.meetCounselBrief > div:first-child {
    margin-bottom: 4em;
}

.meetCounselBriefName {
    margin-top: 2em;
}

.meetCounselBriefName h5 {
    font-size: 1.6em !important
}

.meetCounselBriefName p {
    color: var(--textColor);
}

.meetCounselBriefMore {
    position: absolute;
    right: -70px;
    bottom: 16px;
    margin: 0;
}


@media screen and (max-width: 600px) {
    .homeContainer h2 {
        font-size: 1.5em !important;
        font-weight: 600;
    }

    .homeCarousel {
        height: 200px;
    }
    
    .aboutBrief > div:first-child h2 {
        width: 100%;
    }

    .aboutBrief > div:first-child p {
        width: 100%;
        margin: 2em auto;
    }

    .aboutBrief > div:last-child img {
        width: 100%;
    }

    .practiceBriefCard> div:last-child h5 {
        font-size: 14px !important;
        text-align: center;
        margin: 0;
    }
    .practiceBriefCard > div:last-child p {
        margin: 10px 0 0;
        font-size: 12px;
        text-align: center;
    }

    .practiceBriefCard > div:first-child {
        padding: 20px 10px;
    }

    .meetCounselBriefName h5 {
        font-size: 1.1em !important;
    }
    .meetCounselBrief {
        margin: 2rem 0;
    }

    .aboutAbu {
        margin: 2em 1em;
    }
}

@media screen and (min-width: 601px) and (max-width: 1024px) {
    .aboutBrief > div:first-child h2 {
        width: 80%;
    }

    .aboutBrief > div:first-child p {
        width: 70%;
        margin: 2em auto;
    }

    .aboutBrief > div:last-child img {
        width: 70%;
    }
    
}
@media screen and (max-width: 1024px) {
    .aboutBrief {
        display: block;
    }
    .aboutBrief > div:first-child h2 {
        text-align: center;
    }

    .aboutBrief > div:last-child,
    .meetCounselBrief h2  {
        text-align: center;
    }

    .practiceBrief h2 {
        text-align: center;
        margin-bottom: 1em;
    }
    .ourPhilosophy p {
        width: 100%;
    }

    .meetCounselBriefImg img{
        width: 90%;
    }

    .meetCounselBrief > div {
        text-align: center;
    }
}

